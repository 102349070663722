import { useEffect, useState, createRef } from "react";
import { AdornoDivisor } from "./AdornoDivisor";
import { Corazon } from "./Corazon";
import adorno_corazon_cuenta from '../animations/corazon-falta.json';
import lottie from "lottie-web";
import { useTranslation } from "react-i18next";

function CuentaAtras() {
  const { t } = useTranslation();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [End, setEnd] = useState(false);
  
  const updateTime = () => {
    var fechaCuentaRegresiva = "07/05/2025 18:30:00";
    // Set the date we're counting down to
    var countDownDate = new Date(fechaCuentaRegresiva).getTime();
    // Update the count down every 1 second

    // Get today's date and time
    var now = new Date().getTime();
    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
    if(distance < 0 ) {
        setEnd(true);
    }
  }

  useEffect(() => {
    updateTime();
    const interval = setInterval(() => {
        updateTime()
      }, 1000);
    return () => clearInterval(interval); 
  }, []);

  let animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      animationData: adorno_corazon_cuenta
    });
    return () => anim.destroy();
  }, [animationContainer]);

  return (
    <section className="cuenta-regresiva degradez-opcional-var">
        <div className="adornos-divisor d-flex justify-content-between">
                <AdornoDivisor/>
                <AdornoDivisor/>
                <AdornoDivisor/>
                <AdornoDivisor/>
         </div>
         <div className="container">
         <div className="row d-flex justify-content-center align-items-center">


                <div className="box-cta-regresiva text-center">

                {!End && (<span className="falta">{t("faltan")}</span>)}

                <div id="reloj" className="reloj">
                    {End ? (
                        <p className="fin-cuenta">
                          {t("end_countdown")}                            
                        </p>
                    ) :(
                        <>                  
                            <div id="dias" className="reloj-col">
                            <span className="number">{days}</span>
                            <span className="time">{t("dias")}</span>
                            </div>

                            <div id="horas" className="reloj-col">
                            <span className="number">{hours}</span>
                            <span className="time">{t("horas")}</span>
                            </div>

                            <div id="minutos" className="reloj-col">
                            <span className="number">{minutes}</span>
                            <span className="time">{t("minutos")}</span>
                            </div>

                            <div id="segundos" className="reloj-col no-border">
                            <span className="number">{seconds}</span>
                            <span className="time">{t("segundos")}</span>
                            </div>
                        </>
                    )}

                    <div className="clearfix"></div>

                </div>


                <div className="d-flex justify-content-center align-items-center">

                    <div className="corazon-falta" ref={animationContainer}>
                        <Corazon/>
                     </div>

                </div>

                </div>

                </div>
         </div>
         <div className="adornos-divisor d-flex justify-content-between">
                <AdornoDivisor/>
                <AdornoDivisor/>
                <AdornoDivisor/>
                <AdornoDivisor/>
         </div>
    </section>      
  );
}

export default CuentaAtras;